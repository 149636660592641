import React, { useState } from "react";
import { Checkbox, Drawer } from "antd";
import ButtonClick from "./Button";

const filterData = [
  {
    id: 1,
    items: "Pending",
    value: null,
  },
  {
    id: 2,
    items: "Under Process",
    value: "0",
  },
  {
    id: 3,
    items: "Hired",
    value: "1",
  },
  {
    id: 4,
    items: "Disqualified",
    value: "2",
  },
  {
    id: 5,
    items: "All",
    value: "All",
  },
];

const DrawerRight = ({
  onClose,
  open,
  width,
  setCheckBoxValue,
  handleSubmit,
}) => {
  const [checkedValue, setCheckedValue] = useState("");

  return (
    <Drawer
      title="Apply Filter"
      onClose={() => {
        onClose();
        setCheckedValue("");
      }}
      open={open}
      width={width}
    >
      <div>
        <div className="flex justify-between">
          <p className="text-[14px] font-bold mb-3">Status</p>
          <p className="text-[13px] mb-3 italic">
            Total : {filterData?.length}
          </p>
        </div>
        {filterData?.map((data, i) => (
          <div className="flex items-center mb-3" key={i}>
            <div className="mr-3">
              <Checkbox
                checked={checkedValue === data.id ? true : false}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setCheckBoxValue(data.value);
                    setCheckedValue(data.id);
                  }
                }}
              />
            </div>
            <div>
              <p className="text-[12px]">{data.items}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="bottom-5 right-5 absolute">
        <ButtonClick
          buttonName={"Apply Filter"}
          BtnType={"primary"}
          handleSubmit={() => {
            if (checkedValue) {
              handleSubmit();
            }
          }}
        />
      </div>
    </Drawer>
  );
};

export default DrawerRight;
