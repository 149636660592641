/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import DrawerPop from "../common/DrawerPop";
import Accordion from "../common/Accordion";
import { useTranslation } from "react-i18next";
import { Tooltip, notification } from "antd";
import TextArea from "../common/TextArea";
import FormInput from "../common/FormInput";
import ToggleBtn from "../common/ToggleBtn";
import Dropdown from "../common/Dropdown";
import { MdDelete } from "react-icons/md";
import { Form } from "../data";
import { CgAdd } from "react-icons/cg";
import {
  getAllRecruitmentEvaluationTemplates,
  updateEvaluationTemplateWithDetails,
  getRecruitmentEvaluationTemplateById,
  saveRecruitmentEvaluationTemplate,
  saveRecruitmentEvaluationTemplateDetailBatch,
} from "../Api1";
import { useFormik } from "formik";
import AddMore from "../common/AddMore";
import { RiDeleteBinLine } from "react-icons/ri";

const TemEvaluation = ({
  open = "",
  close = () => {},
  inputshow = false,
  isUpdate = {},
  updateId,
  refresh,
}) => {
  const primaryColor = localStorage.getItem("mainColor");

  const companyId = localStorage.getItem("companyId");

  const [evaluationlist, setevaluationlist] = useState([]);

  const [evaluation, setEvaluation] = useState([
    {
      id: 1,
      companyId: companyId,
      evaluationTemplateId: "",
      question: "",
      answerMetaData: [],
      description: "description",
      createdBy: 499,
      mandatoryStatus: false,
    },
  ]);

  const [templateName, setTemplateName] = useState("");

  const [Length, setLength] = useState("");

  const handleAddCondition = () => {
    if (!updateId) {
      setEvaluation((prevEvaluation) => [
        ...prevEvaluation,
        {
          id: prevEvaluation.length + 1,
          companyId: companyId,
          evaluationTemplateId: "",
          question: "",
          answerMetaData: [],
          description: "description",
          createdBy: 493,
          mandatoryStatus: false,
        },
      ]);
    } else {
      setEvaluation((prevEvaluation) => [
        ...prevEvaluation,
        {
          companyId: companyId,
          evaluationTemplateId: "",
          evaluationTemplateDetailsId: null,
          question: "",
          answerMetaData: [],
          description: "description",
          mandatoryStatus: false,
        },
      ]);
    }
  };

  const handleDeleteCondition = (index) => {
    setEvaluation((prevEvaluation) =>
      prevEvaluation.filter((_, i) => i !== index)
    );
  };

  const handleDeleteField = (conditionIndex, fieldIndex) => {
    setEvaluation((prevEvaluation) =>
      prevEvaluation.map((prevCondition, i) =>
        i === conditionIndex
          ? {
              ...prevCondition,
              answerMetaData: (prevCondition.answerMetaData || []).filter(
                (field, j) => j !== fieldIndex
              ),
            }
          : prevCondition
      )
    );
  };

  const handleAddField = (index, selectedvalue) => {
    setEvaluation((prevEvaluation) =>
      prevEvaluation.map((prevCondition, i) =>
        i === index
          ? {
              ...prevCondition,
              answerMetaData: [
                ...prevCondition.answerMetaData,
                {
                  id: prevCondition.answerMetaData.length + 1,
                  key: selectedvalue,
                  value: "",
                },
              ],
            }
          : prevCondition
      )
    );
  };

  const [successNotificationVisible, setSuccessNotificationVisible] =
    useState(false);

  const [show, setShow] = useState(open);

  const { t } = useTranslation();

  const handleClose = () => {
    close(false);
  };

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
      placement: "top",
      // stack: 2,
      style: {
        background: `${
          type === "success"
            ? `linear-gradient(180deg, rgba(204, 255, 233, 0.8) 0%, rgba(235, 252, 248, 0.8) 51.08%, rgba(246, 251, 253, 0.8) 100%)`
            : "linear-gradient(180deg, rgba(255, 236, 236, 0.80) 0%, rgba(253, 246, 248, 0.80) 51.13%, rgba(251, 251, 254, 0.80) 100%)"
        }`,
        boxShadow: `${
          type === "success"
            ? "0px 4.868px 11.358px rgba(62, 255, 93, 0.2)"
            : "0px 22px 60px rgba(134, 92, 144, 0.20)"
        }`,
      },
      // duration: null,
    });
  };

  const [Questionerror, setQuestionError] = useState("");

  const [answerError, setAnswerError] = useState("");

  const [OptionError, setoptionserror] = useState("");

  const formik = useFormik({
    initialValues: {
      companyId: "",
      evaluationTemplateName: "",
      description: "",
      createdBy: null,
    },

    // enableReinitialize: true,
    // validateOnChange: false,
    // validationSchema: Yup.object().shape({
    //   evaluationTemplateName : Yup.string().required('Evalutaion is required'),
    //   description:Yup.string().required('Description is required'),
    // }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        let hasError = false;
        let regex = RegExp(/^[A-Za-z]*$/);
        const result = regex.test(formik.values.evaluationTemplateName);
        if (
          !formik.values.evaluationTemplateName ||
          !formik.values.description
        ) {
          formik.setFieldError(
            "evaluationTemplateName",
            !formik.values.evaluationTemplateName
              ? "Template Name is required"
              : !result
              ? "Invalid Characters not allowed"
              : ""
          );
          formik.setFieldError(
            "description",
            !formik.values.description ? "Description is required" : ""
          );
          hasError = true;
        }
        if (
          formik.values.evaluationTemplateName &&
          formik.values.evaluationTemplateName.length < 3
        ) {
          formik.setFieldError(
            "evaluationTemplateName",
            "Template Name should have at least 3 letters."
          );
          hasError = true;
        }
        if (Length > 0) {
          formik.setFieldError(
            "evaluationTemplateName",
            "Template name already exist"
          );
          hasError = true;
        }
        evaluation.forEach((condition) => {
          if (condition.question.length < 3) {
            setQuestionError(
              `Question is required and should have at least 3 letters.`
            );
            hasError = true;
          }
          if (!condition.answerMetaData || !condition.answerMetaData[0]?.key) {
            setAnswerError("Question Type is required");
            hasError = true;
          }
          if (
            ["Drop-down", "Multiple Choice", "Checkboxes"].includes(
              condition.answerMetaData[0]?.key
            ) &&
            (condition.answerMetaData.some((field) => !field.value) ||
              (!condition.answerMetaData[0]?.value &&
                condition.answerMetaData[0]?.key))
          ) {
            setoptionserror("Option is required");
            hasError = true;
          }
        });
        if (hasError) {
          return;
        }
        if (updateId) {
          const formattedData = evaluation.map((item, index) => ({
            companyId: companyId,
            evaluationTemplateId: updateId,
            question: item.question,
            answerMetaData: item.answerMetaData,
            description: item.description,
            createdBy: item.createdBy,
            mandatoryStatus: item.mandatoryStatus,
            evaluationTemplateDetailsId: item.evaluationTemplateDetailsId,
            modifiedBy: null,
          }));
          const response = await updateEvaluationTemplateWithDetails({
            RecruitmentEvaluationTemplate: {
              evaluationTemplateId: updateId,
              companyId: companyId,
              evaluationTemplateName: values.evaluationTemplateName,
              description: values.description,
              modifiedBy: null,
            },
            RecruitmentEvaluationTemplateDetail: formattedData,
          });
          if (response.status === 200) {
            openNotification("success", "Successful", response.message);
            setSuccessNotificationVisible(true);
            setTimeout(() => {
              handleClose();
              refresh();
            }, 1500);
          } else if (response.status == 500) {
            openNotification(
              "error",
              "Info",
              response.message.replace(/<br\/>/g, "\n")
            );
          }
        } else {
          const response = await saveRecruitmentEvaluationTemplate({
            companyId: companyId,
            evaluationTemplateName: values.evaluationTemplateName,
            description: values.description,
            createdBy: null,
          });
          if (response.status === 200) {
            const insertedId = response.result.insertedId;
            const formattedData = evaluation.map((item) => ({
              companyId: companyId,
              evaluationTemplateId: insertedId,
              question: item.question,
              answerMetaData: JSON.stringify(item.answerMetaData),
              description: item.description,
              mandatoryStatus: item.mandatoryStatus,
              createdBy: item.createdBy,
            }));
            const response2 =
              await saveRecruitmentEvaluationTemplateDetailBatch(formattedData);
            if (response2.status === 200) {
              openNotification("success", "Successful", response2.message);
              setSuccessNotificationVisible(true);
              setTimeout(() => {
                handleClose();
                refresh();
              }, 1500);
            } else if (response2.status === 500) {
              openNotification("error", "Info", response2.message);
            }
          } else if (response.status === 500) {
            openNotification("error", "Info", response.message);
          }
        }
      } catch (error) {
        openNotification("error", "Info", "Template Name Already Exists");
      }
      setSubmitting(false);
    },
  });

  const handleSubmit = async (e) => {
    formik.handleSubmit();
  };

  const getevaluationtem = async () => {
    const id = updateId;
    try {
      const response = await getRecruitmentEvaluationTemplateById({ id });
      setevaluationlist(response.result);
      const evaluationData = response.result.flatMap((item) => {
        return item.evaluationTemplateDetailData.map((detail) => ({
          companyId: detail.companyId,
          question: detail.question,
          evaluationTemplateDetailsId: detail.evaluationTemplateDetailsId,
          description: detail.description,
          evaluationTemplateId: detail.evaluationTemplateId,
          isActive: detail.isActive,
          modifiedBy: null,
          modifiedOn: detail.modifiedOn,
          mandatoryStatus: detail.mandatoryStatus,
          answerMetaData: detail.answerMetaData.map((metadata) => ({
            key: metadata.key,
            value: metadata.value,
          })),
        }));
      });
      setEvaluation(evaluationData);
      const firstEvaluation = response.result[0];
      formik.setFieldValue(
        "evaluationTemplateName",
        firstEvaluation.evaluationTemplateName
      );
      formik.setFieldValue("description", firstEvaluation.description);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (updateId) {
      getevaluationtem();
    }
  }, [updateId]);

  const getEvalautaionTemplalateByName = async () => {
    try {
      const response = await getAllRecruitmentEvaluationTemplates({
        companyId: companyId,
        evaluationTemplateName: templateName,
      });
      setLength(response.result.length);
      if (response.result.length > 0) {
        formik.setFieldError(
          "evaluationTemplateName",
          "Template name already exist"
        );
        return;
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getEvalautaionTemplalateByName();
  }, [templateName]);

  return (
    <div>
      <DrawerPop
        open={show}
        contentWrapperStyle={{
          position: "absolute",
          height: "100%",
          top: 0,
          // left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          borderRadius: 0,
          borderTopLeftRadius: "0px !important",
          borderBottomLeftRadius: 0,
        }}
        close={(e) => {
          setShow(e);
          //    setUpdateId(null);
          handleClose();
        }}
        header={[
          !updateId
            ? t("Create Evaluation Template")
            : t("Update Evaluation Template"),
          !updateId
            ? t("Create Evaluation Template")
            : t("Update Evaluation Template"),
        ]}
        //  headerRight={
        //    <div className="flex items-center gap-10">
        //      <p className="text-sm font-medium text-gray-400">
        //        Draft Saved 10 Seconds ago
        //      </p>
        //      <div className="flex items-center gap-2.5">
        //        <p className="text-sm font-medium text-gray-400">{t("Help")}</p>
        //        <RxQuestionMarkCircled className="text-2xl font-medium text-gray-400 " />
        //      </div>
        //    </div>
        //  }
        footerBtn={[t("Cancel"), t("Save")]}
        className="widthFull"
        handleSubmit={(e) => handleSubmit(e)}
        //  buttonClickCancel={(e) => {
        //    if (activeBtn > 0) {
        //      setActiveBtn(activeBtn - 1);
        //      setNextStep(nextStep - 1);
        //      setActiveBtnValue(steps?.[activeBtn - 1].data);
        //    }
        //    setBtnName("");
        //  }}
        //  nextStep={nextStep}
        //  activeBtn={activeBtn}
        //  saveAndContinue={true}
        //  stepsData={steps}
      >
        <div className="relative max-w-[1070px] w-full mx-auto">
          <Accordion
            title={"Evaluation Template"}
            description={"Evaluation Template"}
            className="Text_area"
            padding={true}
            click={() => {
              //    setPresentage(1.4);
            }}
            initialExpanded={true}
          >
            <div className="grid grid-cols-2">
              <FormInput
                title={"Template Name"}
                placeholder={"Enter Template Name"}
                value={formik.values.evaluationTemplateName}
                change={(e) => {
                  formik.setFieldValue("evaluationTemplateName", e);
                  setTemplateName(e);
                }}
                error={formik.errors.evaluationTemplateName}
                required={true}
              />
            </div>
            <div className="grid grid-cols-2">
              <TextArea
                title={"Description"}
                placeholder={"Enter Description"}
                value={formik.values.description}
                change={(e) => {
                  formik.setFieldValue("description", e);
                }}
                error={formik.errors.description}
                required={true}
              />
            </div>
            <div className="flex flex-col gap-4 overflow-hidden">
              {evaluation.map((condition, index) => (
                <>
                  <div className="flex items-center justify-between">
                    <FormInput
                      // showValueParagraph={true}
                      title={`Question ${index + 1}`}
                      placeholder={`Enter Question ${index + 1}`}
                      value={condition.question}
                      change={(e) => {
                        setEvaluation((prevEvaluation) =>
                          prevEvaluation.map((prevCondition, i) =>
                            i === index
                              ? { ...prevCondition, question: e }
                              : prevCondition
                          )
                        );
                      }}
                      error={
                        condition.question.length > 3 ? "" : Questionerror || ""
                      }
                      required={true}
                    />
                    <div className="flex items-center gap-5 mt-4">
                      <div className="">
                        <Dropdown
                          options={Form}
                          dropdownWidth="250px"
                          change={(e) => {
                            setEvaluation((prevEvaluation) =>
                              prevEvaluation.map((prevCondition, i) =>
                                i === index
                                  ? {
                                      ...prevCondition,
                                      answerMetaData: [
                                        {
                                          key: e,
                                          value:
                                            e ===
                                            condition.answerMetaData[0]?.key
                                              ? condition.answerMetaData[0]
                                                  ?.value
                                              : "",
                                        },
                                      ],
                                    }
                                  : prevCondition
                              )
                            );
                            handleAddField(e);
                          }}
                          value={condition.answerMetaData[0]?.key}
                          icondropDown={true}
                          required={true}
                          error={
                            condition.answerMetaData[0]?.key
                              ? ""
                              : answerError || ""
                          }
                          placeholder={"Choose Question Type"}
                        />
                      </div>
                      {/* Additional dynamic input fields based on the selected value in the dropdown */}
                      {/* Add your logic here */}
                      <div className="mr-6">
                        <Tooltip
                          placement="topRight"
                          color={primaryColor}
                          title={"Mandatory / Optional"}
                          className="flex items-center gap-2"
                        >
                          <p>Mandatory</p>
                          <ToggleBtn
                            value={condition.mandatoryStatus}
                            change={(e) => {
                              const temp = [...evaluation];
                              temp[index].mandatoryStatus = e;
                              setEvaluation(temp);
                            }}
                          />
                        </Tooltip>
                      </div>
                      {index !== 0 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "-40px",
                          }}
                        >
                          <Tooltip placement="top" color={"red"} title="Delete">
                            <RiDeleteBinLine
                              className="text-gray-500 hover:text-red-500"
                              style={{
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDeleteCondition(index)}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                  {condition.answerMetaData[0]?.key && (
                    <>
                      {/* Render existing FormInput components */}
                      {condition.answerMetaData.map((field, fieldIndex) => (
                        <div key={fieldIndex} className="flex items-center">
                          {[
                            "Drop-down",
                            "Multiple Choice",
                            "Checkboxes",
                          ].includes(field.key) && (
                            <FormInput
                              title={`Option ${fieldIndex + 1}`}
                              placeholder={"Enter option"}
                              value={field.value}
                              change={(e) =>
                                setEvaluation((prevEvaluation) =>
                                  prevEvaluation.map((prevCondition, i) =>
                                    i === index
                                      ? {
                                          ...prevCondition,
                                          answerMetaData:
                                            prevCondition.answerMetaData.map(
                                              (f, j) =>
                                                j === fieldIndex
                                                  ? { ...f, value: String(e) }
                                                  : f
                                            ),
                                        }
                                      : prevCondition
                                  )
                                )
                              }
                              error={field.value ? "" : OptionError || ""}
                            />
                          )}
                          {[
                            "Drop-down",
                            "Multiple Choice",
                            "Checkboxes",
                          ].includes(field.key) && (
                            <div className="ml-2">
                              <Tooltip placement="top" title={"Delete"}>
                                <MdDelete
                                  onClick={() =>
                                    handleDeleteField(index, fieldIndex)
                                  }
                                  className="cursor-pointer text-red-500"
                                />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      ))}
                      {["Drop-down", "Multiple Choice", "Checkboxes"].includes(
                        condition.answerMetaData[0]?.key
                      ) && (
                        <Tooltip placement="top" title={"Add new"}>
                          <CgAdd
                            onClick={() =>
                              handleAddField(
                                index,
                                condition.answerMetaData[0]?.key
                              )
                            }
                            style={{
                              width: "18px",
                              height: "18px",
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                  <div className="v-divider"></div>
                </>
              ))}
              <div className="flex items-center gap-2">
                <AddMore
                  name="Add New Question"
                  className="!text-black"
                  change={(e) => {
                    handleAddCondition();
                  }}
                />
              </div>
            </div>
            {/* <div className='border-t'></div>
            <div className='flex flex-col gap-2'>
              <div className='dark:text-white'>Overall Score</div>
              <div className='grid grid-cols-2'>
                <FormInput
                  placeholder={"Type question here"}
                />
              </div> 
              <div className="w-full  rounded-sm h-24 sm:w-full mt-5">
                <div className="bg-white rounded-md borderb  p-4 flex dark:bg-black dark:text-white h-24">
                  <div className="flex items-center w-1/5 sm:w-1/5">
                    <div className="ml-4">
                      <div className='flex items-center flex-col gap-1'>
                        <p className='bg-slate-400 rounded-full text-md p-1 opacity-60 font-medium'> <IoCloseSharp /></p>
                        <p className="font-bold text-gray-400 justify-center">Strong No</p>
                      </div>
                    </div>
                  </div>
                  <div className="h-divider !border-gray-300 ml-12"></div>
                  <div className="flex items-center w-1/5 sm:w-1/5">
                    <div className="ml-8">
                      <div className='flex items-center flex-col gap-1'>
                        <p className='text-lg p-1 opacity-60 font-medium'> <HiMiniHandThumbDown className='text-gray-500' /></p>
                        <p className="font-bold text-gray-400 justify-center">No</p>
                      </div>
                    </div>
                  </div>
                  <div className="h-divider !border-gray-300 ml-12"></div>
                  <div className="flex items-center w-1/5 sm:w-1/5">
                    <div className="ml-8">
                      <div className='flex items-center flex-col gap-1'>
                        <p className='bg-slate-400 rounded-full text-md p-1 opacity-60 font-medium'> <TiMinus /></p>
                        <p className="font-bold text-gray-400 justify-center">Not Sure</p>
                      </div>
                    </div>
                  </div>
                  <div className="h-divider !border-gray-300 ml-12"></div>
                  <div className="flex items-center w-1/5 sm:w-1/5">
                    <div className="ml-8">
                      <div className='flex items-center flex-col gap-1'>
                        <p className='text-lg p-1 opacity-60 font-medium'> <HiMiniHandThumbUp className='text-gray-500' /></p>
                        <p className="font-bold text-gray-400 justify-center">Yes</p>
                      </div>
                    </div>
                  </div>
                  <div className="h-divider !border-gray-300 ml-12"></div>
                  <div className="flex items-center w-1/5 sm:w-1/5">
                    <div className="ml-8">
                      <div className='flex items-center flex-col gap-1'>
                        <p className='text-lg p-1 opacity-60 font-medium'> <FaStar className='text-gray-500' /></p>
                        <p className="font-bold text-gray-400 justify-center">Strong Yes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {contextHolder}
          </Accordion>
        </div>
      </DrawerPop>
    </div>
  );
};

export default TemEvaluation;
